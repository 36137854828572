.WelcomeTab__title {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #595959;
}

.WelcomeTab__desc {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #595959;
  padding-bottom: 10px;
}

.WelcomeTab__desc:first-of-type {
  padding-bottom: 20px;
}

.WelcomeTab__header {
  font-weight: 700;
  font-size: 28px;
  color: var(--button-bg-color);
  max-width: 500px;
}

.WelcomeTab__content {
  padding-bottom: 15px;
  max-width: 768px;
}

.WelcomeTab ul {
  margin-top: 0;
}

.WelcomeTab__link, .WelcomeTab__link:active, .WelcomeTab__link:visited, .WelcomeTab__link:link {
  color: blue;
  padding: 5px 0;
  display: inline-block;
}
