.Upload {
	&__input {
		display: flex;
		align-items: center;
		padding-top: 5px;
	}

	&__title {
		font-weight: 700;
		color: var(--question-color);
		display: flex;
		align-items: center;
	}

	&__filename {
		padding: 0 5px;
		font-weight: 700;
		text-decoration: underline;
		color: black;
	}

	&__icon {
		height: 24px;
		width: 24px;
		background: url('~assets/file_icon.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		margin-left: 10px;
	}

	&__filename:hover {
		color: var(--button-bg-color);
	}

	&__delete_file {
		position: absolute;
		background: url(~assets/close.svg) no-repeat center;
		width: 25px;
		height: 25px;
		margin-inline-start: 510px;
		cursor: pointer;
	}

	& > input {
		display: none;
	}
}
