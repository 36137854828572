.RadioGroup__title {
  padding-bottom: 5px;
  font-weight: 700;
  color: var(--question-color);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.RadioGroup__inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.RadioGroup .radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10px;
}

body[dir='ltr'] .RadioGroup .radio:first-of-type {
  padding-left: 0;
}

body[dir='rtl'] .RadioGroup .radio:first-of-type {
  padding-right: 0;
}

.RadioGroup .radio__element {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid white;
  background: white;
  -webkit-box-shadow: 0 0 0 2px var(--button-bg-color);
          box-shadow: 0 0 0 2px var(--button-bg-color);
  -webkit-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
  cursor: pointer;
}

.RadioGroup .radio__element--selected {
  background: var(--button-bg-color);
}

.RadioGroup .radio__label {
  padding: 0 9px;
}

.RadioGroup._disabled .radio__element {
  -webkit-box-shadow: 0 0 2px black;
          box-shadow: 0 0 2px black;
  cursor: default;
}

.RadioGroup._disabled .radio__element--selected {
  background: #c3c3c3;
}
