.Input > .title {
  color: var(--question-color);
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 700;
}

.Input input {
  width: 100%;
  height: 35px;
  padding-right: 10px;
  padding-left: 10px;
}

.Input__wrapper {
  position: relative;
}
