@-webkit-keyframes popup {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes popup {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.InfoBox {
  background: url("~assets/info_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  margin: 0 5px;
  display: inline-block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.InfoBox__dropshadow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.InfoBox__dropshadow.close {
  display: none;
}

.InfoBox__dropshadow.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}

.InfoBox__dropshadow .info {
  border-radius: 10px;
  max-width: 768px;
  padding: 10px 20px;
  font-size: 20px;
  background-color: white;
  position: relative;
}

.inside {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 26px;
  height: 26px;
}

.tooltip {
  max-width: 300px;
}

.tooltip.rtl .multi-line {
  text-align: right !important;
}

.tooltip.ltr .multi-line {
  text-align: left !important;
}

.info__close {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  font-size: 22px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  font-weight: 600;
  background-color: var(--button-bg-color);
  cursor: pointer;
}

body[dir='rtl'] .info__close {
  right: auto;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) {
  .InfoBox {
    width: 26px;
    height: 26px;
  }
  .info__close {
    position: absolute;
    top: -8px;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
}
