.Header {
	background-color: var(--header-color);
	padding-inline: 15px 45px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 0px 6px #00000021;
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 70px;
	border-bottom: var(--header-border);
	.logo {
		background: var(--application-logo);
		background-size: var(--application-logo-size);
		width: 18%;
		height: 50px;
		min-width: 285px;
	}
	&.large_size .logo {
		width: 18%;
		min-width: 240px;
	}

	.burger {
		display: none;
	}
	&__tab_links {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		text-align: center;
		margin-inline-end: 50px;
		.large_size & {
			grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
			margin-inline-end: 5px;
			.nav_item {
				padding: 10px 4px;
			}
		}
		.nav_item {
			padding: 5px 27px;
			cursor: pointer;
			display: block;
			color: var(--header-nav-color);
			text-decoration: none;
			// background: url('~assets/bullet_gray_26x27.png');
			background-repeat: no-repeat;
			transition: background-color 150ms ease-in-out;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			height: 70px;
			&--welcome {
				// background-image: url('~assets/file_32x24.png') !important;
			}
			&--selected {
				background-color: var(--button-focus-bg-color) !important;
				color: var(--button-focus-color) !important;
			}
			&--disabled {
				background-color: var(--disabled-color) !important;
				color: #ebebe4 !important;
				&.nav_item:hover {
					background-color: var(--disabled-color) !important;
					color: #ebebe4 !important;
				}
			}
			&--completed {
				// background-image: url('~assets/bullet_green_26x27.png');
				position: relative;
				&::before {
					content: '';
					width: 100%;
					height: 5px;
					position: absolute;
					background-color: var(--header-nav-bg-completed) !important;
					top: 0;
				}
			}
		}
		.nav_item:hover {
			background-color: var(--button-hover-bg-color) !important;
			color: var(--button-hover-color) !important;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.Header {
		background: linear-gradient(
			-120deg,
			var(--header-color) 1%,
			var(--header-color) 79%,
			var(--side-menu-bg-mobile-color) 79%,
			var(--side-menu-bg-mobile-color) 100%
		);
		padding: 8px 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: initial;
		position: fixed;

		body[dir='rtl'] & {
			background: linear-gradient(
				120deg,
				var(--header-color) 1%,
				var(--header-color) 79%,
				var(--side-menu-bg-color) 79%,
				var(--side-menu-bg-color) 100%
			);
		}

		.logo {
			background-size: var(--application-logo-mobile-size);
			background-position: center;
			width: 100%;
		}

		.burger {
			background: transparent;
			display: block;
			.line {
				display: block;
				width: 28px;
				height: 3px;
				background-color: var(--header-color);
				margin-block: 5px;
				border-radius: 10px;
				transition: transform 500ms;
			}
			&.open .line {
				&:nth-child(1) {
					transform: translateY(8px) rotate(45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: translateY(-8px) rotate(-45deg);
				}
			}
		}
		&__tab_links {
			display: none;
		}
	}
}

// media query mobile
@media only screen and (max-width: 767px) {
	.Header .logo {
		min-width: auto;
	}
}
