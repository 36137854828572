.UploadImage__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.UploadImage__title {
  padding-bottom: 20px;
  font-weight: 700;
  color: var(--question-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.UploadImage__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.UploadImage__filename {
  font-weight: 700;
}

.UploadImage__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-right: 20px;
}

body[dir='rtl'] .UploadImage__image {
  margin-right: 0;
  margin-left: 20px;
}

.UploadImage > input {
  display: none;
}

.UploadImage img {
  width: 150px;
  height: 150px;
}

.UploadImage .button {
  width: 152px;
}

.UploadImage .insturctions {
  width: 1100px;
  height: auto;
  margin: auto;
  display: block;
}

.UploadImage .modal__close {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  font-size: 22px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  font-weight: 600;
  background-color: var(--button-bg-color);
  color: var(--button-color);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .UploadImage .insturctions {
    width: 320px;
    height: auto;
    margin: auto;
    display: block;
  }
  .UploadImage .content_container {
    max-height: 80vh;
    overflow-y: scroll;
  }
  .UploadImage__container {
    display: block;
  }
  .UploadImage__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    border: none;
    margin-right: 20px;
    height: 150px;
  }
  .UploadImage .modal__close {
    position: absolute;
    top: 0;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
}
