.MultiDDL {
	select {
		width: 100%;
	}
	.hiddenInput {
		height: 0;
		width: 0;
		background-color: transparent;
		border: none;
	}

	&__title {
		font-weight: 700;
		color: var(--question-color);
	}
}

@media only screen and (max-width: 1200px) {
}
