@keyframes popup {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

.InfoBox {
	background: url('~assets/info_icon.svg');
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	margin: 0 5px;
	display: inline-block;
	flex-shrink: 0;
	width: 20px;
	height: 20px;

	&__dropshadow {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 10;

		&.close {
			display: none;
		}
		&.open {
			display: flex;
			animation-name: popup;
			animation-duration: 0.3s;
		}
		justify-content: center;
		align-items: center;

		.info {
			border-radius: 10px;
			max-width: 768px;
			padding: 10px 20px;
			font-size: 20px;
			background-color: white;
			position: relative;
		}
	}

	cursor: pointer;
}

.inside {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	transform: translate(0, -50%);
	width: 26px;
	height: 26px;
}

.tooltip {
	max-width: 300px;
}

.tooltip.rtl {
	.multi-line {
		text-align: right !important;
	}
}

.tooltip.ltr {
	.multi-line {
		text-align: left !important;
	}
}

.info__close {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 50%;
	transform: translate(50%, -50%);
	font-size: 22px;
	line-height: 40px;
	height: 40px;
	width: 40px;
	text-align: center;
	font-weight: 600;
	background-color: var(--button-bg-color);
	cursor: pointer;
}

body[dir='rtl'] .info__close {
	right: auto;
	left: 0;
	transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) {
	.InfoBox {
		width: 26px;
		height: 26px;
	}
	.info__close {
		position: absolute;
		top: -8px;
		right: 50%;
		transform: translate(50%, -50%);
	}
}
