.ErrorPage .container {
  max-width: 1200px;
  width: 100%;
  display: inherit;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  margin: auto;
  padding: 0 210px;
}

.ErrorPage__header {
  background-color: var(--header-color);
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  border: var(--header-border);
}

.ErrorPage__header .logo {
  background: var(--application-logo) no-repeat;
  background-size: contain;
  width: 150px;
  height: 40px;
}

.ErrorPage__logo {
  background: var(--application-logo);
  background-position: center left 20px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
}

.ErrorPage .ErrorPage__main {
  width: 100%;
  background: url("~assets/bg_1200_500.png") no-repeat center top 50%;
  background-size: 50% 50%;
  padding-top: 73px;
  height: 845px;
}

.ErrorPage__text {
  color: #201a17;
  font-size: 22px;
  width: 320px;
  margin-bottom: 35px;
  font-weight: 700;
}

.ErrorPage__redirect {
  text-decoration: none;
}

.ErrorPage__footer {
  text-align: center;
  color: #141a22;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .ErrorPage .container {
    max-width: 768px;
  }
  .ErrorPage .ErrorPage__main {
    background-size: 400px auto;
  }
  .ErrorPage__text {
    margin-bottom: 18px;
  }
  .ErrorPage__footer.container {
    padding-top: 20px;
  }
  body[dir='rtl'] .ErrorPage .ErrorPage__main {
    background-size: 400px auto;
  }
}

@media only screen and (max-width: 767px) {
  .ErrorPage .container {
    max-width: none;
    padding: 0 20px;
  }
  .ErrorPage__text {
    margin: auto;
    margin-bottom: 18px;
    max-width: 320px;
    width: auto;
  }
  .ErrorPage .ErrorPage__main {
    height: 700px;
    text-align: center;
    padding-top: 33px;
    padding-bottom: 253px;
    background-size: 300px auto;
    background-position: center;
  }
}
