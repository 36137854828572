.Tab {
  overflow: auto;
  width: 100%;
  padding: 30px 40px;
  scroll-behavior: smooth;
  padding-left: 340px;
}

.Tab .summaryScoreTableQSW,
.Tab .summaryScoreTable {
  width: 100%;
  margin-top: 25px;
  margin: 25px 0;
}

.Tab .summaryScoreTableQSW__header,
.Tab .summaryScoreTable__header {
  font-weight: bolder;
}

.Tab .summaryScoreTableQSW__header, .Tab .summaryScoreTableQSW__body__row,
.Tab .summaryScoreTable__header,
.Tab .summaryScoreTable__body__row {
  background-color: #f2f2f2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 1fr 1fr;
      grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  margin-top: 1px;
}

.Tab .summaryScoreTableQSW__header > div, .Tab .summaryScoreTableQSW__body__row > div,
.Tab .summaryScoreTable__header > div,
.Tab .summaryScoreTable__body__row > div {
  padding: 10px;
}

.Tab .summaryScoreTableQSW__header--total, .Tab .summaryScoreTableQSW__body__row--total,
.Tab .summaryScoreTable__header--total,
.Tab .summaryScoreTable__body__row--total {
  background-color: #e0e0e0;
  font-weight: bolder;
}

.Tab .summaryScoreTableQSW__header.over_max_point, .Tab .summaryScoreTableQSW__body__row.over_max_point,
.Tab .summaryScoreTable__header.over_max_point,
.Tab .summaryScoreTable__body__row.over_max_point {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--userprogress-bg-light)), to(var(--userprogress-bg-deep)));
  background-image: linear-gradient(to right, var(--userprogress-bg-light) 0%, var(--userprogress-bg-deep) 100%);
}

.Tab .summaryScoreTableQSW__header .maximum, .Tab .summaryScoreTableQSW__body__row .maximum,
.Tab .summaryScoreTable__header .maximum,
.Tab .summaryScoreTable__body__row .maximum {
  background: var(--side-menu-bg-color);
  color: var(--side-menu-color);
}

body[dir='rtl'] .Tab {
  padding-left: 40px;
  padding-right: 340px;
}

.Tab .questions {
  padding: 30px;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: minmax(auto, 530px);
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  grid-gap: 0 10px;
  background-color: #f2f2f2;
}

.Tab .questions > div {
  margin-top: 10px;
}

.Tab .questions .question__wrapper {
  -webkit-padding-after: 10px;
          padding-block-end: 10px;
}

.Tab .questions .question__wrapper .question__title {
  color: var(--section-title-color);
  font-size: 16px;
  font-weight: bolder;
  -webkit-padding-after: 10px;
          padding-block-end: 10px;
}

.Tab .questions .question__wrapper:has(.Note) {
  grid-column: span 3;
  padding-bottom: 30px;
  font-size: 15px;
}

.Tab .questions .question__wrapper .textArea_title {
  padding-bottom: 10px;
  font-weight: 700;
  color: var(--question-color);
}

.Tab .questions .question__wrapper textarea {
  min-height: 174px;
}

.Tab__section {
  padding-bottom: 40px;
}

.Tab__section__remove {
  background: url("~assets/remove_icon.svg") center no-repeat;
  width: 28px;
  height: 21px;
  cursor: pointer;
}

.Tab__section > .title {
  font-weight: bold;
  color: var(--section-title-color);
  background-color: #e0e0e0;
  font-size: 24px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Tab__section .document_desc {
  max-width: 75%;
  line-height: 21px;
}

.Tab__section .document_link {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
}

.Tab__section .document_link a {
  padding: 0 20px;
}

.Tab .Modal.service_agreement .Modal__content {
  overflow: hidden;
  width: 60%;
}

.Tab .Modal.service_agreement .Iframe {
  height: calc(100vh - 175px);
}

@media only screen and (max-width: 1200px) {
  .Tab {
    width: 100%;
    padding: 80px 20px 20px;
  }
  body[dir='rtl'] .Tab {
    padding: 80px 20px 20px;
  }
  .Tab__section {
    max-width: 768px;
    margin: auto;
  }
  .Tab .questions {
    display: block;
  }
  .Tab .questions > div {
    margin: 10px 0 20px;
  }
  .Tab .questions .question__wrapper .question__title {
    color: var(--question-color);
    font-size: 16px;
  }
  .Tab__section.mobile_padding {
    -webkit-margin-start: 10px auto;
            margin-inline-start: 10px auto;
  }
  .Tab__section > .title {
    padding: 10px;
    font-weight: bold;
  }
  .Tab__section .questions {
    padding: 5px 10px 10px;
  }
  .Tab .Modal.service_agreement .Modal__content {
    overflow: hidden;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .Tab,
  body[dir='rtl'] .Tab {
    padding: 10px;
    padding-top: 80px;
  }
  .Tab .Modal.service_agreement .Modal__content,
  body[dir='rtl'] .Tab .Modal.service_agreement .Modal__content {
    overflow: hidden;
    width: 100%;
  }
}

.Skeleton {
  --skeleton-bg: #dddddd90;
  --skeleton-height: 50px;
  --skeleton-width-1: 1400px;
  --skeleton-wave-bg: rgba(255, 255, 255, 0.3);
}

.Skeleton .tab_body {
  width: 100%;
  height: 90vh;
  display: block;
  position: relative;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Skeleton .tab_body::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(100deg, transparent, var(--skeleton-wave-bg) 50%, transparent 80%), linear-gradient(var(--skeleton-bg) 1000px, transparent 0), linear-gradient(var(--skeleton-bg) 75px, transparent 0);
  background-size: 20px 1000px, var(--skeleton-width-1) 1000px, var(--skeleton-width-1) 1000px;
  background-position: 0% 0%, 0% 0%, 0% 0%;
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: skeleton-loader 1.7s linear infinite;
          animation: skeleton-loader 1.7s linear infinite;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 0% 0%, 0% 0%, 0% 0%;
  }
  100% {
    background-position: 150% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 0% 0%, 0% 0%, 0% 0%;
  }
  100% {
    background-position: 150% 0%, 0% 0%, 0% 0%;
  }
}

@media only screen and (max-width: 767px) {
  .Skeleton .tab_body::after {
    -webkit-animation: skeleton-loader 0.9s linear infinite;
            animation: skeleton-loader 0.9s linear infinite;
  }
}
