.Checkbox {
	display: flex;

	&__element {
		height: 20px;
		width: 20px;
		border: 1.5px solid var(--button-bg-color);
		border-radius: 2px;
		cursor: pointer;

		&::before {
			content: '';
			background-color: transparent;
			width: 40%;
			height: 80%;
			display: inline-block;
			border-bottom: 2px solid transparent;
			border-right: 2px solid transparent;
			transform: translate(70%, -15%) rotate(45deg);
			transition: all 150ms ease-in-out;
		}

		&--selected::before {
			border-bottom-color: var(--button-bg-color);
			border-right-color: var(--button-bg-color);
		}
	}

	&__label {
		padding: 0 10px;
	}
}
