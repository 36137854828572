@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8) rotate(0.5turn);
            transform: scale(0.8) rotate(0.5turn);
  }
  100% {
    -webkit-transform: scale(1) rotate(1turn);
            transform: scale(1) rotate(1turn);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8) rotate(0.5turn);
            transform: scale(0.8) rotate(0.5turn);
  }
  100% {
    -webkit-transform: scale(1) rotate(1turn);
            transform: scale(1) rotate(1turn);
  }
}

@-webkit-keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
            transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
            transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.9, 1.1);
            transform: scale(0.9, 1.1);
  }
}

@keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
            transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
            transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.9, 1.1);
            transform: scale(0.9, 1.1);
  }
}

@-webkit-keyframes bounce {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  50% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
}

@keyframes bounce {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  50% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
}

.Spinner {
  cursor: progress;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Spinner .dropshadow {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dropshadow-color);
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.Spinner .dropshadow .spinning {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 5px solid var(--main-color);
  border-top: 5px solid var(--side-menu-bg-color);
  -webkit-animation: spin 1s linear forwards infinite;
          animation: spin 1s linear forwards infinite;
}

.ChangesSpinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #464a53;
  font-size: 18px;
}

.ChangesSpinner .spinning {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  padding: 15px;
  background-size: 25px 25px !important;
}

.ChangesSpinner .spinning.loading {
  background: var(--changes_spiner) no-repeat center;
  -webkit-animation: scale 2s linear forwards infinite;
          animation: scale 2s linear forwards infinite;
}

.ChangesSpinner .spinning.success {
  background: var(--changes_spiner_done) no-repeat center;
  -webkit-animation: bounce 1s linear;
          animation: bounce 1s linear;
}

.ChangesSpinner .spinning.failed {
  background: var(--changes_spiner_fail) no-repeat center;
  -webkit-animation: gelatine 1s linear infinite;
          animation: gelatine 1s linear infinite;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
}
