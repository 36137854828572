.Iframe {
	width: 100%;
	max-height: 100vh;
	height: calc(100vh - 70px);

	// padding-left: 300px;
	iframe {
		border: 0;
	}
}

.Iframe {
	overflow: hidden;
	iframe {
		background-color: var(--login-bg-color);
	}
}
