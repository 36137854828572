.UploadImage {
	&__input {
		display: flex;
		align-items: flex-start;
	}

	&__title {
		padding-bottom: 20px;
		font-weight: 700;
		color: var(--question-color);
		display: flex;
		align-items: center;
	}

	&__details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__filename {
		font-weight: 700;
	}

	&__image {
		display: flex;
		align-items: flex-end;
		margin-right: 20px;
	}
	body[dir='rtl'] &__image {
		margin-right: 0;
		margin-left: 20px;
	}

	& > input {
		display: none;
	}
	img {
		width: 150px;
		height: 150px;
	}
	.button {
		width: 152px;
	}

	.insturctions {
		width: 1100px;
		height: auto;
		margin: auto;
		display: block;
	}

	.modal__close {
		position: absolute;
		top: 0;
		right: 0;

		border-radius: 50%;
		transform: translate(50%, -50%);
		font-size: 22px;
		line-height: 40px;
		height: 40px;
		width: 40px;
		text-align: center;
		font-weight: 600;
		background-color: var(--button-bg-color);
		color: var(--button-color);
		cursor: pointer;
	}
}

@media only screen and (max-width: 1200px) {
	.UploadImage {
		.insturctions {
			width: 320px;
			height: auto;
			margin: auto;
			display: block;
		}

		.content_container {
			max-height: 80vh;
			overflow-y: scroll;
		}

		&__container {
			display: block;
		}
		&__image {
			display: flex;
			align-items: flex-end;
			border: none;
			margin-right: 20px;
			height: 150px;
		}

		.modal__close {
			position: absolute;
			top: 0;
			right: 50%;
			transform: translate(50%, -50%);
		}
	}
}
