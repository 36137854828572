.SideTabs {
	color: var(--side-menu-color);
	background: var(--side-menu-bg-color);
	padding: 30px 20px;
	width: 300px;
	min-width: 300px;
	min-height: calc(100vh - 70px);
	z-index: 1;
	top: 70px;
	bottom: 0;
	position: fixed;
	display: flex;
	flex-direction: column;

	.user-details {
		.unique {
			font-size: 13px;
		}
		.spouse_link a {
			color: var(--side-menu-color) !important;
		}
	}

	.main-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		&__tab_links {
			overflow-y: scroll;
			overflow-x: hidden;
		}
		.nav_item {
			display: none;
			padding: 10px 20px;
			cursor: pointer;
			color: white;
			text-decoration: none;
			background-repeat: no-repeat;
			font-size: 14px;
			&--welcome {
			}
			&--selected {
			}
			&--completed {
			}
		}
		.nav_item:hover {
			background-color: var(--button-hover-bg-color);
			color: var(--button-hover-color);
		}
		.contact {
			//for contact like

			//for tabs like list
			display: flex;
			flex-direction: column;

			a,
			a:link,
			a:active,
			a:visited {
				text-decoration: none;
				font-size: 14px;
				padding: 5px 10px;
				color: var(--side-menu-color);
			}
			a:hover {
				background-color: var(--button-hover-bg-color);
				color: var(--button-hover-color);
			}
		}
		.logout {
			display: flex;
			cursor: pointer;
			&__image {
				-webkit-mask-image: url('~assets/log_out.svg');
				mask-image: url('~assets/log_out.svg');
				mask-repeat: no-repeat;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-position: left center;
				mask-position: left center;
				padding-inline-start: 30px;
				background-color: var(--side-menu-color);
				min-width: 20px;
				min-height: 20px;
			}
			&__text {
				text-transform: uppercase;
				color: var(--side-menu-color);
				&:hover {
					color: var(--button-hover-color);
				}
			}
		}
		.logout_divider {
			margin-top: auto;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.SideTabs {
		padding: 10px 20px;
		.user-details {
			padding: 0px 10px;
		}
		.nav_item {
			padding: 10px 20px;
			&--selected {
				background-color: var(--button-focus-bg-color) !important;
				color: var(--button-focus-color) !important;
			}
		}

		.main-content {
			.contact {
				width: 100%;
				display: flex;
				justify-content: space-evenly;
				flex-direction: row;
				font-size: 18px;
				font-weight: 600;
				align-items: flex-end;
				margin-top: auto;
				flex-wrap: wrap;
			}
			.nav_item {
				display: block;
				color: var(--mobile-link-color);
			}
			.logout_divider {
				margin-top: 20px;
			}
		}
		z-index: 100;
		transition: all 250ms ease-in-out;
		top: 69px;
		&.close {
			transform: translateX(-100%);
			body[dir='rtl'] & {
				transform: translateX(100%);
			}
		}
		&.open {
			transform: translateX(0);
		}
	}
}

//media query mobile
@media only screen and (max-width: 767px) {
	.SideTabs {
		.main-content {
			.contact {
				flex-wrap: wrap-reverse;
				padding-top: 20px;
			}
		}
	}
}
