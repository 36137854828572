.Checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Checkbox__element {
  height: 20px;
  width: 20px;
  border: 1.5px solid var(--button-bg-color);
  border-radius: 2px;
  cursor: pointer;
}

.Checkbox__element::before {
  content: '';
  background-color: transparent;
  width: 40%;
  height: 80%;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  -webkit-transform: translate(70%, -15%) rotate(45deg);
          transform: translate(70%, -15%) rotate(45deg);
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.Checkbox__element--selected::before {
  border-bottom-color: var(--button-bg-color);
  border-right-color: var(--button-bg-color);
}

.Checkbox__label {
  padding: 0 10px;
}
