.ErrorPage {
	.container {
		max-width: 1200px;
		width: 100%;
		display: inherit;
		justify-content: inherit;
		align-items: inherit;
		margin: auto;
		padding: 0 210px;
	}

	&__header {
		background-color: var(--header-color);
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border: var(--header-border);
		.logo {
			background: var(--application-logo) no-repeat;
			background-size: contain;
			width: 150px;
			height: 40px;
		}
	}

	&__logo {
		background: var(--application-logo);
		background-position: center left 20px;
		background-size: contain;
		background-repeat: no-repeat;
		height: 36px;
	}

	.ErrorPage__main {
		width: 100%;
		background: url('~assets/bg_1200_500.png') no-repeat center top 50%;
		background-size: 50% 50%;
		padding-top: 73px;
		height: 845px;
	}

	&__text {
		color: #201a17;
		font-size: 22px;
		width: 320px;
		margin-bottom: 35px;
		font-weight: 700;
	}

	&__redirect {
		text-decoration: none;
	}

	&__footer {
		text-align: center;
		color: #141a22;
		font-size: 14px;
		font-weight: 700;
		padding-bottom: 20px;
	}
}

@media only screen and (max-width: 1199px) {
	.ErrorPage {
		.container {
			max-width: 768px;
		}

		.ErrorPage__main {
			background-size: 400px auto;
		}

		&__text {
			margin-bottom: 18px;
		}
		&__footer.container {
			padding-top: 20px;
		}
	}
	body[dir='rtl'] .ErrorPage {
		.ErrorPage__main {
			background-size: 400px auto;
		}
	}
}
@media only screen and (max-width: 767px) {
	.ErrorPage {
		.container {
			max-width: none;
			padding: 0 20px;
		}

		&__text {
			margin: auto;
			margin-bottom: 18px;
			max-width: 320px;
			width: auto;
		}
		.ErrorPage__main {
			height: 700px;
			text-align: center;
			padding-top: 33px;
			padding-bottom: 253px;
			background-size: 300px auto;
			background-position: center;
		}
	}
}
