.LanguageSelect {
	min-width: auto;

	select {
		appearance: none;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		background-color: transparent;
		color: var(--header-nav-color);
		font-weight: 700;
		border: 2px solid;
		font-size: 12px;
		padding: 5px;
	}
	option {
		color: #333;
	}
}
