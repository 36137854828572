.MonthPickerDDL {
	.date_picker {
		display: flex;

		.month_value:disabled,
		.year_value:disabled {
			background-color: #e7e6e666;
		}
	}

	input {
		background-color: #fff;
		border-color: #d9d9d9 #ccc #b3b3b3;
		border-radius: 4px;
		border: 1px solid #ccc;
		color: #333;
		cursor: default;
		outline: none;
		width: 100%;
		-webkit-appearance: none;
	}

	&__title {
		padding-bottom: 5px;
		font-weight: 700;
		color: var(--question-color);
	}

	&__input {
		position: relative;
	}
	&__input > input {
		height: 35px;
		width: 100%;
		padding: 0 10px;
	}
}
