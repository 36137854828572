.Tab {
	overflow: auto;
	width: 100%;
	padding: 30px 40px;
	scroll-behavior: smooth;

	padding-left: 340px;
	.summaryScoreTableQSW,
	.summaryScoreTable {
		width: 100%;
		margin-top: 25px;
		margin: 25px 0;
		&__header {
			font-weight: bolder;
		}
		&__header,
		&__body__row {
			background-color: #f2f2f2;
			display: grid;
			grid-template-columns: 2fr 1fr 1fr;
			gap: 10px;
			margin-top: 1px;
			> div {
				padding: 10px;
			}
			&--total {
				background-color: #e0e0e0;
				font-weight: bolder;
			}
			&.over_max_point {
				background-image: linear-gradient(to right, var(--userprogress-bg-light) 0%, var(--userprogress-bg-deep) 100%);
			}
			.maximum {
				background: var(--side-menu-bg-color);
				color: var(--side-menu-color);
			}
		}
	}
	body[dir='rtl'] & {
		padding-left: 40px;
		padding-right: 340px;
	}

	.questions {
		padding: 30px;
		display: grid;
		grid-auto-columns: minmax(auto, 530px);
		align-items: end;

		grid-gap: 0 10px;
		background-color: #f2f2f2;

		> div {
			margin-top: 10px;
		}

		.question__wrapper {
			padding-block-end: 10px;
			.question__title {
				color: var(--section-title-color);
				font-size: 16px;
				font-weight: bolder;
				padding-block-end: 10px;
			}
			&:has(.Note) {
				grid-column: span 3;
				padding-bottom: 30px;
				font-size: 15px;
			}
			.textArea_title {
				padding-bottom: 10px;
				font-weight: 700;
				color: var(--question-color);
			}
			textarea {
				min-height: 174px;
			}
		}
	}

	&__section {
		padding-bottom: 40px;

		&__remove {
			background: url('~assets/remove_icon.svg') center no-repeat;
			width: 28px;
			height: 21px;
			cursor: pointer;
		}

		> .title {
			font-weight: bold;
			color: var(--section-title-color);
			background-color: #e0e0e0;
			font-size: 24px;
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.document_desc {
			max-width: 75%;
			line-height: 21px;
		}

		.document_link {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 20px;
			display: block;
			a {
				padding: 0 20px;
			}
		}
	}
	.Modal.service_agreement {
		.Modal__content {
			overflow: hidden;
			width: 60%;
		}
		.Iframe {
			height: calc(100vh - 175px);
		}
	}
}

@media only screen and (max-width: 1200px) {
	.Tab {
		width: 100%;
		padding: 80px 20px 20px;
		body[dir='rtl'] & {
			padding: 80px 20px 20px;
		}

		&__section {
			max-width: 768px;
			margin: auto;
		}

		.questions {
			display: block;
			> div {
				margin: 10px 0 20px;
			}

			.question__wrapper {
				.question__title {
					color: var(--question-color);
					font-size: 16px;
				}
			}
		}

		&__section {
			&.mobile_padding {
				margin-inline-start: 10px auto;
			}

			> .title {
				padding: 10px;
				font-weight: bold;
			}

			.questions {
				padding: 5px 10px 10px;
			}
		}
		.Modal.service_agreement {
			.Modal__content {
				overflow: hidden;
				width: 90%;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.Tab,
	body[dir='rtl'] .Tab {
		padding: 10px;
		padding-top: 80px;
		.Modal.service_agreement {
			.Modal__content {
				overflow: hidden;
				width: 100%;
			}
		}
	}
}

.Skeleton {
	--skeleton-bg: #dddddd90;
	--skeleton-height: 50px;
	--skeleton-width-1: 1400px;
	--skeleton-wave-bg: rgba(255, 255, 255, 0.3);
	.tab_body {
		width: 100%;
		height: 90vh;
		display: block;
		position: relative;
		background: #fff;
		box-sizing: border-box;
	}
	.tab_body::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background: linear-gradient(100deg, transparent, var(--skeleton-wave-bg) 50%, transparent 80%),
			linear-gradient(var(--skeleton-bg) 1000px, transparent 0), linear-gradient(var(--skeleton-bg) 75px, transparent 0);
		background-size: 20px 1000px, var(--skeleton-width-1) 1000px, var(--skeleton-width-1) 1000px;
		background-position: 0% 0%, 0% 0%, 0% 0%;
		background-repeat: no-repeat;
		box-sizing: border-box;
		animation: skeleton-loader 1.7s linear infinite;
	}

	@keyframes skeleton-loader {
		0% {
			background-position: 0% 0%, 0% 0%, 0% 0%;
		}
		100% {
			background-position: 150% 0%, 0% 0%, 0% 0%;
		}
	}
}

//media query mobile
@media only screen and (max-width: 767px) {
	.Skeleton .tab_body::after {
		animation: skeleton-loader 0.9s linear infinite;
	}
}
