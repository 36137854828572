.Iframe {
  width: 100%;
  max-height: 100vh;
  height: calc(100vh - 70px);
}

.Iframe iframe {
  border: 0;
}

.Iframe {
  overflow: hidden;
}

.Iframe iframe {
  background-color: var(--login-bg-color);
}
