.Upload__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5px;
}

.Upload__title {
  font-weight: 700;
  color: var(--question-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Upload__filename {
  padding: 0 5px;
  font-weight: 700;
  text-decoration: underline;
  color: black;
}

.Upload__icon {
  height: 24px;
  width: 24px;
  background: url("~assets/file_icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 10px;
}

.Upload__filename:hover {
  color: var(--button-bg-color);
}

.Upload__delete_file {
  position: absolute;
  background: url(~assets/close.svg) no-repeat center;
  width: 25px;
  height: 25px;
  -webkit-margin-start: 510px;
          margin-inline-start: 510px;
  cursor: pointer;
}

.Upload > input {
  display: none;
}
