.SideTabs {
  color: var(--side-menu-color);
  background: var(--side-menu-bg-color);
  padding: 30px 20px;
  width: 300px;
  min-width: 300px;
  min-height: calc(100vh - 70px);
  z-index: 1;
  top: 70px;
  bottom: 0;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.SideTabs .user-details .unique {
  font-size: 13px;
}

.SideTabs .user-details .spouse_link a {
  color: var(--side-menu-color) !important;
}

.SideTabs .main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.SideTabs .main-content__tab_links {
  overflow-y: scroll;
  overflow-x: hidden;
}

.SideTabs .main-content .nav_item {
  display: none;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  background-repeat: no-repeat;
  font-size: 14px;
}

.SideTabs .main-content .nav_item:hover {
  background-color: var(--button-hover-bg-color);
  color: var(--button-hover-color);
}

.SideTabs .main-content .contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.SideTabs .main-content .contact a,
.SideTabs .main-content .contact a:link,
.SideTabs .main-content .contact a:active,
.SideTabs .main-content .contact a:visited {
  text-decoration: none;
  font-size: 14px;
  padding: 5px 10px;
  color: var(--side-menu-color);
}

.SideTabs .main-content .contact a:hover {
  background-color: var(--button-hover-bg-color);
  color: var(--button-hover-color);
}

.SideTabs .main-content .logout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.SideTabs .main-content .logout__image {
  -webkit-mask-image: url("~assets/log_out.svg");
  mask-image: url("~assets/log_out.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left center;
  mask-position: left center;
  -webkit-padding-start: 30px;
          padding-inline-start: 30px;
  background-color: var(--side-menu-color);
  min-width: 20px;
  min-height: 20px;
}

.SideTabs .main-content .logout__text {
  text-transform: uppercase;
  color: var(--side-menu-color);
}

.SideTabs .main-content .logout__text:hover {
  color: var(--button-hover-color);
}

.SideTabs .main-content .logout_divider {
  margin-top: auto;
}

@media only screen and (max-width: 1200px) {
  .SideTabs {
    padding: 10px 20px;
    z-index: 100;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    top: 69px;
  }
  .SideTabs .user-details {
    padding: 0px 10px;
  }
  .SideTabs .nav_item {
    padding: 10px 20px;
  }
  .SideTabs .nav_item--selected {
    background-color: var(--button-focus-bg-color) !important;
    color: var(--button-focus-color) !important;
  }
  .SideTabs .main-content .contact {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: 18px;
    font-weight: 600;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-top: auto;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .SideTabs .main-content .nav_item {
    display: block;
    color: var(--mobile-link-color);
  }
  .SideTabs .main-content .logout_divider {
    margin-top: 20px;
  }
  .SideTabs.close {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  body[dir='rtl'] .SideTabs.close {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .SideTabs.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .SideTabs .main-content .contact {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    padding-top: 20px;
  }
}
