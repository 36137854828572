@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.s {
	/* DEFAULT */
	--header-color: #a0a0a0;
	--side-menu-bg-color: #a0a0a0;
	--side-menu-bg-mobile-color: #a0a0a0;
	--userprogress-bg-deep: #a0a0a0;
	--userprogress-bg-light: #a0a0a0;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #fff;
	--header-nav-bg-completed: #fff;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #a0a0a0;
	--button-hover-color: #fff;
	--button-hover-bg-color: #a0a0a0;
	--button-focus-bg-color: #a0a0a0;
	--button-focus-color: #fff;
	--button-border-radius: 0px;
	--question-color: #a0a0a0;
	--application-logo: url('~assets/LogoBerry.png') no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 50px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 0px solid transperent;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, Montserrat, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
select[disabled],
input[disabled] {
	cursor: not-allowed !important;
}

iframe {
	max-width: 100%;
}

.validation_error {
	color: #ff0033;
}

.stopScroll {
	overflow: hidden;
}

.required {
	color: red;
}

.divider {
	height: 2px;
	width: 100%;
	background-color: #fff;
	margin: 20px 0;
	opacity: 0.3;
}

.button {
	background-color: var(--button-bg-color);
	color: var(--button-color);
	font-weight: 500;
	font-size: 14px;
	padding: 1em 2em;
	display: inline-block;
	border: 0px;
	cursor: pointer;
	transition: background-color 150ms ease-in-out, transform 250ms;
	text-align: center;
	border-radius: var(--button-border-radius);
}
.button:hover {
	transform: translateY(-2px);
	background-color: var(--button-hover-bg-color);
	color: var(--button-hover-color);
}
.button:focus {
	transform: translateY(-2px);
	background-color: var(--button-focus-bg-color);
	color: var(--button-focus-color);
}

.button.disabled:hover {
	transform: none;
}

.loader {
	background-image: url('~assets/loader.svg');
	background-size: 25%;
	background-position: center;
	background-repeat: no-repeat;
	padding: 1.5em 5em;
	text-align: center;
	display: inline-block;
	border-radius: 30px;
}

.loader__big {
	width: 100%;
	background-color: var(--header-color);
	mask: url('~assets/loader.svg');
	mask-repeat: no-repeat;
	mask-size: 25%;
	mask-position: center;
}

.primary {
	background-color: var(--button-bg-color);
	color: var(--button-color);
}
.primary:hover {
	background-color: var(--button-hover-bg-color);
	color: var(--button-hover-color);
}

.secondary {
	background-color: var(--button-hover-bg-color);
	color: var(--button-hover-color);
}
.secondary:hover {
	background-color: var(--button-bg-color);
	color: var(--button-color);
}

.disabled:hover,
.disabled {
	border-color: var(--button-disabled-color);
	background-color: var(--button-disabled-color);
	cursor: not-allowed;
}

.nav_item--selected {
	color: var(--userprogress-color) !important;
}

.icon {
	background-repeat: no-repeat;
	background-position: center left;
	padding-left: 25px;
}

body[dir='rtl'] .icon {
	background-position: center right;
	padding-left: 0;
	padding-right: 25px;
}

.icon__add {
	background-image: url('~assets/plus_icon.svg');
}

textarea,
.input,
input[type='tel'],
input[type='email'],
input[type='date'],
input[type='text'],
input[type='number'],
select {
	background-color: #fff;
	border-color: #d9d9d9 #ccc #b3b3b3;
	border-radius: 4px;
	border: 1px solid #ccc;
	color: #333;
	cursor: default;
	height: 36px;
	padding: 0 10px;
	outline: none;
	width: 100%;
	cursor: pointer;
}
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background-color: var(--accent-color);
}
::-webkit-scrollbar-thumb {
	background-color: var(--accent-color-deep);
}

/*media query tablet*/
@media only screen and (max-width: 1199px) {
}

/*media query mobile*/
@media only screen and (max-width: 767px) {
	.container {
		max-width: none;
	}
	body[lang] {
	}
}
