.MonthPicker__title {
  padding-bottom: 5px;
  font-weight: 700;
  color: var(--question-color);
}

.MonthPicker__input {
  position: relative;
  width: 100%;
  overflow: visible;
  height: 35px;
}

.MonthPicker__input .date {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 100%;
  height: 35px;
  padding: 5px;
  font-size: 18px;
  color: #777;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.MonthPicker__input .date--disable {
  cursor: not-allowed;
  background-color: #ebebe4;
  color: #545454;
}

.MonthPicker__input .date .date-icon {
  background: url("~assets/date.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 10px 0 0;
}

.MonthPicker__input .dates-container {
  background: white;
  border-radius: 3px;
  height: 0;
  top: 36px;
  position: absolute;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
      grid-template-areas: 'leftArrow year rightArrow' 'months months months';
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
}

.MonthPicker__input .dates-container--open {
  height: 220px;
  -webkit-box-shadow: 0 0 10px 1px #444;
          box-shadow: 0 0 10px 1px #444;
}

.MonthPicker__input .dates-container .left-arrow {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: leftArrow;
  background: url("~assets/arrow.png");
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  background-repeat: no-repeat;
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  height: 19px;
  width: 17px;
  cursor: pointer;
}

.MonthPicker__input .dates-container .right-arrow {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: rightArrow;
  background: url("~assets/arrow.png");
  background-repeat: no-repeat;
  height: 19px;
  width: 17px;
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  cursor: pointer;
}

.MonthPicker__input .dates-container .hidden-element {
  background: unset;
  pointer-events: none;
}

.MonthPicker__input .dates-container .year {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: year;
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 21px;
  color: #777;
  padding-bottom: 5px;
}

.MonthPicker__input .dates-container .months-grid {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: months;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto;
      grid-template-columns: auto auto auto;
}

.MonthPicker__input .dates-container .months-grid .month {
  padding: 10px 25px;
  text-align: center;
  cursor: pointer;
  color: #777;
}

.MonthPicker__input .dates-container .months-grid .month.pointer-events-none {
  pointer-events: none;
  color: #7777779a;
}

.MonthPicker__input .dates-container .months-grid .month:hover {
  background: #777;
  color: white;
}
