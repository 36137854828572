@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
@keyframes scale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.8) rotate(0.5turn);
	}
	100% {
		transform: scale(1) rotate(1turn);
	}
}
@keyframes gelatine {
	from,
	to {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(0.9, 1.1);
	}
	50% {
		transform: scale(1.1, 0.9);
	}
	75% {
		transform: scale(0.9, 1.1);
	}
}
@keyframes bounce {
	0%,
	60%,
	80%,
	100% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-2px);
	}
	50% {
		transform: translateY(2px);
	}
}
.Spinner {
	cursor: progress;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	.dropshadow {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--dropshadow-color);
		width: 100%;
		height: 100%;
		padding-top: 100px;
		.spinning {
			position: fixed;
			top: 50%;
			left: 50%;
			height: 50px;
			width: 50px;
			border-radius: 25px;
			border: 5px solid var(--main-color);
			border-top: 5px solid var(--side-menu-bg-color);
			animation: spin 1s linear forwards infinite;
		}
	}
}
.ChangesSpinner {
	display: flex;
	align-items: center;
	color: #464a53;
	font-size: 18px;
	.spinning {
		margin-inline-end: 10px;
		padding: 15px;
		background-size: 25px 25px !important;
		&.loading {
			background: var(--changes_spiner) no-repeat center;
			animation: scale 2s linear forwards infinite;
		}
		&.success {
			background: var(--changes_spiner_done) no-repeat center;
			animation: bounce 1s linear;
		}
		&.failed {
			background: var(--changes_spiner_fail) no-repeat center;
			animation: gelatine 1s linear infinite;
			animation-iteration-count: 2;
		}
	}
}
