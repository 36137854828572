.App .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
}

.App .modal__title {
  text-align: center;
  font-size: 22px;
  padding-bottom: 10px;
}

.App .modal__close,
.App .modal__ok {
  margin: 10px 10px;
}

.App .info__close {
  background-color: var(--button-bg-color);
  color: var(--button-color);
}

.App .info__close:hover {
  background-color: var(--button-hover-bg-color);
  color: var(--button-hover-color);
}

.App .modal_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.App .newlook_popup {
  background: url(~assets/newlook.png) no-repeat center right -35px;
  background-size: 550px auto;
  padding-right: 450px;
  padding-left: 20px;
  padding-top: 30px;
  width: 800px;
  position: relative;
  min-height: 260px;
}

.App .newlook_popup .newlook_text {
  max-width: 310px;
}

.App .newlook_popup .button {
  margin-top: 20px;
}

.App .newlook_popup .close {
  background-image: url(~assets/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

body[dir='rtl'] .App .newlook_popup {
  background-position: bottom left;
  padding-left: 400px;
  padding-right: 20px;
}

body[dir='rtl'] .App .newlook_popup .close {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 1200px) {
  .App .content {
    padding-top: 0px;
  }
  .App .newlook_popup {
    background-size: 360px auto;
    background-position: bottom right -10px;
    padding-right: 30px;
    padding-top: 30px;
    width: 620px;
    padding-bottom: 30px;
  }
  .App .newlook_popup .newlook_text {
    max-width: 310px;
  }
  body[dir='rtl'] .App .newlook_popup {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .App .newlook_popup {
    background-size: 310px auto;
    background-position: center bottom;
    max-width: 270px;
    padding-bottom: 260px;
  }
  .App .newlook_popup .newlook_text {
    max-width: none;
    text-align: initial;
  }
  body[dir='rtl'] .App .newlook_popup {
    padding-left: 0;
    background-position: center bottom;
  }
}
