.Score {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.Score__container select {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.question__wrapper:has(> .Score) {
  background-color: #ffcece;
  padding: 10px;
}
