.Header {
  background-color: var(--header-color);
  padding-inline: 15px 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 0px 6px #00000021;
          box-shadow: 0px 0px 6px #00000021;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 70px;
  border-bottom: var(--header-border);
}

.Header .logo {
  background: var(--application-logo);
  background-size: var(--application-logo-size);
  width: 18%;
  height: 50px;
  min-width: 285px;
}

.Header.large_size .logo {
  width: 18%;
  min-width: 240px;
}

.Header .burger {
  display: none;
}

.Header__tab_links {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  text-align: center;
  -webkit-margin-end: 50px;
          margin-inline-end: 50px;
}

.large_size .Header__tab_links {
  -ms-grid-columns: (minmax(94px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  -webkit-margin-end: 5px;
          margin-inline-end: 5px;
}

.large_size .Header__tab_links .nav_item {
  padding: 10px 4px;
}

.Header__tab_links .nav_item {
  padding: 5px 27px;
  cursor: pointer;
  display: block;
  color: var(--header-nav-color);
  text-decoration: none;
  background-repeat: no-repeat;
  -webkit-transition: background-color 150ms ease-in-out;
  transition: background-color 150ms ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  height: 70px;
}

.Header__tab_links .nav_item--selected {
  background-color: var(--button-focus-bg-color) !important;
  color: var(--button-focus-color) !important;
}

.Header__tab_links .nav_item--disabled {
  background-color: var(--disabled-color) !important;
  color: #ebebe4 !important;
}

.Header__tab_links .nav_item--disabled.nav_item:hover {
  background-color: var(--disabled-color) !important;
  color: #ebebe4 !important;
}

.Header__tab_links .nav_item--completed {
  position: relative;
}

.Header__tab_links .nav_item--completed::before {
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: var(--header-nav-bg-completed) !important;
  top: 0;
}

.Header__tab_links .nav_item:hover {
  background-color: var(--button-hover-bg-color) !important;
  color: var(--button-hover-color) !important;
}

@media only screen and (max-width: 1200px) {
  .Header {
    background: linear-gradient(-120deg, var(--header-color) 1%, var(--header-color) 79%, var(--side-menu-bg-mobile-color) 79%, var(--side-menu-bg-mobile-color) 100%);
    padding: 8px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
  }
  body[dir='rtl'] .Header {
    background: linear-gradient(120deg, var(--header-color) 1%, var(--header-color) 79%, var(--side-menu-bg-color) 79%, var(--side-menu-bg-color) 100%);
  }
  .Header .logo {
    background-size: var(--application-logo-mobile-size);
    background-position: center;
    width: 100%;
  }
  .Header .burger {
    background: transparent;
    display: block;
  }
  .Header .burger .line {
    display: block;
    width: 28px;
    height: 3px;
    background-color: var(--header-color);
    margin-block: 5px;
    border-radius: 10px;
    -webkit-transition: -webkit-transform 500ms;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
  }
  .Header .burger.open .line:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
  }
  .Header .burger.open .line:nth-child(2) {
    opacity: 0;
  }
  .Header .burger.open .line:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
            transform: translateY(-8px) rotate(-45deg);
  }
  .Header__tab_links {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .Header .logo {
    min-width: auto;
  }
}
