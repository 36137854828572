.RadioGroup {
	&__title {
		padding-bottom: 5px;
		font-weight: 700;
		color: var(--question-color);
		// display: flex;
		align-items: center;
	}
	&__inputs {
		display: flex;
	}
	.radio {
		display: flex;
		align-items: center;
		padding: 0 10px;

		body[dir='ltr'] &:first-of-type {
			padding-left: 0;
		}
		body[dir='rtl'] &:first-of-type {
			padding-right: 0;
		}
		&__element {
			height: 15px;
			width: 15px;
			border-radius: 50%;
			border: 3px solid white;
			background: white;
			box-shadow: 0 0 0 2px var(--button-bg-color);
			transition: background 250ms ease-in-out;
			cursor: pointer;

			&--selected {
				background: var(--button-bg-color);
			}
		}

		&__label {
			padding: 0 9px;
		}
	}

	&._disabled {
		.radio__element {
			box-shadow: 0 0 2px black;
			cursor: default;
			&--selected {
				background: #c3c3c3;
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
}
