.DDL{
    &__title{
        padding-bottom: 5px;
        font-weight: 700;
        color: var(--question-color)
    }

    select{
        width: 100%;
        padding: 0 5px;
    }

    option{
        font-size: 18px;
    }

    &__container{
        display: flex;
       align-items: center;
    }
}


