@keyframes popup {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

.Modal {
	.container {
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: repeat(1, 1fr);
	}

	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	z-index: 1;
	display: none;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;
	&--open {
		display: block;

		animation-name: popup;
		animation-duration: 0.3s;
	}
	&__content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		z-index: 2;
		box-shadow: 0px 10px 15px -3px #00000010, 0 4px 6px -4px #00000010;
		padding: 50px;
		border-radius: 5px;
		max-height: 90vh;
	}
	.close {
		position: absolute;
		top: 50px;
		right: 50px;
		border-radius: 50%;
		transform: translate(50%, -50%);
		font-size: 22px;
		line-height: 40px;
		height: 40px;
		width: 40px;
		text-align: center;
		font-weight: 600;
		background-color: var(--button-bg-color);
		color: var(--button-color);
		cursor: pointer;
	}
}

@media only screen and (max-width: 1199px) {
	.Modal {
		&__content {
			padding: 25px;
			border-radius: 5px;
		}
	}
}
@media only screen and (max-width: 767px) {
}
