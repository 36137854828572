.WelcomeTab {
	&__title {
		font-size: 16px;
		font-family: Arial, Helvetica, sans-serif;
		color: #595959;
	}

	&__desc {
		font-size: 16px;
		font-family: Arial, Helvetica, sans-serif;
		color: #595959;
		padding-bottom: 10px;
		&:first-of-type {
			padding-bottom: 20px;
		}
	}

	&__header {
		font-weight: 700;
		font-size: 28px;
		color: var(--button-bg-color);
		max-width: 500px;
	}
	&__content {
		padding-bottom: 15px;
		max-width: 768px;
	}

	ul {
		margin-top: 0;
	}
	&__link,
	&__link:active,
	&__link:visited,
	&__link:link {
		color: blue;
		padding: 5px 0;
		display: inline-block;
	}
}
