.DatePicker .date_picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DatePicker input {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
}

.DatePicker__title {
  padding-bottom: 5px;
  font-weight: 700;
  color: var(--question-color);
}

.DatePicker__input {
  position: relative;
}

.DatePicker__input > input {
  height: 35px;
  width: 100%;
  padding: 0 10px;
}
