.DDL__title {
  padding-bottom: 5px;
  font-weight: 700;
  color: var(--question-color);
}

.DDL select {
  width: 100%;
  padding: 0 5px;
}

.DDL option {
  font-size: 18px;
}

.DDL__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
