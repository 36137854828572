.Input {
	> .title {
		color: var(--question-color);
		font-size: 16px;
		padding-bottom: 5px;

		font-weight: 700;
	}

	input {
		width: 100%;
		height: 35px;
		padding-right: 10px;
		padding-left: 10px;
	}

	&__wrapper {
		position: relative;
	}
}

@media only screen and (max-width: 1200px) {
}
