.Score {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	&__container {
		select {
			max-width: fit-content;
		}
	}
}

.question__wrapper:has(> .Score) {
	background-color: #ffcece;
	padding: 10px;
}
