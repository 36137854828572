.MonthPickerDDL .date_picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.MonthPickerDDL .date_picker .month_value:disabled,
.MonthPickerDDL .date_picker .year_value:disabled {
  background-color: #e7e6e666;
}

.MonthPickerDDL input {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
}

.MonthPickerDDL__title {
  padding-bottom: 5px;
  font-weight: 700;
  color: var(--question-color);
}

.MonthPickerDDL__input {
  position: relative;
}

.MonthPickerDDL__input > input {
  height: 35px;
  width: 100%;
  padding: 0 10px;
}
