.MonthPicker {
	&__title {
		padding-bottom: 5px;
		font-weight: 700;
		color: var(--question-color);
	}

	&__input {
		position: relative;
		width: 100%;
		overflow: visible;
		height: 35px;

		.date {
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.35);
			border-radius: 4px;
			width: 100%;
			height: 35px;
			padding: 5px;
			font-size: 18px;
			color: #777;
			cursor: pointer;
			display: flex;
			align-items: center;

			&--disable {
				cursor: not-allowed;
				background-color: rgb(235, 235, 228);
				color: rgb(84, 84, 84);
			}

			.date-icon {
				background: url('~assets/date.png');
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0 10px 0 0;
			}
		}

		.dates-container {
			background: white;
			border-radius: 3px;
			height: 0;
			top: 36px;
			position: absolute;
			overflow: hidden;
			display: grid;
			grid-template-areas:
				'leftArrow year rightArrow'
				'months months months';
			transition: all 250ms ease-in-out;
			width: 50%;
			box-sizing: border-box;
			z-index: 1;

			&--open {
				height: 220px;
				box-shadow: 0 0 10px 1px #444;
			}

			.left-arrow {
				grid-area: leftArrow;
				background: url('~assets/arrow.png');
				transform: rotateY(180deg);
				background-repeat: no-repeat;
				justify-self: center;
				align-self: center;
				height: 19px;
				width: 17px;
				cursor: pointer;
			}

			.right-arrow {
				grid-area: rightArrow;
				background: url('~assets/arrow.png');
				background-repeat: no-repeat;
				height: 19px;
				width: 17px;
				justify-self: center;
				align-self: center;
				cursor: pointer;
			}

			.hidden-element {
				background: unset;
				pointer-events: none;
			}

			.year {
				grid-area: year;
				justify-self: center;
				align-self: center;
				font-size: 21px;
				color: #777;
				padding-bottom: 5px;
			}

			.months-grid {
				grid-area: months;
				display: grid;
				grid-template-columns: auto auto auto;
				.month {
					padding: 10px 25px;
					text-align: center;
					cursor: pointer;
					color: #777;

					&.pointer-events-none {
						pointer-events: none;
						color: #7777779a;
					}

					&:hover {
						background: #777;
						color: white;
					}
				}
			}
		}
	}
}
