.MultiDDL select {
  width: 100%;
}

.MultiDDL .hiddenInput {
  height: 0;
  width: 0;
  background-color: transparent;
  border: none;
}

.MultiDDL__title {
  font-weight: 700;
  color: var(--question-color);
}
