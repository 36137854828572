.Progressbar {
	background-color: #ffffff;
	max-width: 100%;
	padding: 2px;

	.userprogress {
		background-image: linear-gradient(to right, var(--userprogress-bg-light) 0%, var(--userprogress-bg-deep) 100%);
		height: 25px;
		width: 1%;
		transition: width 0.5s cubic-bezier(0.2, 0.5, 0.8, 1.3);
		position: relative;
		&::after {
			content: attr(data-after);
			font-size: 14px;
			color: var(--userprogress-color);
			position: absolute;
			display: block;
			left: 10px;
			width: 100%;
			top: 10%;
			min-width: 200px;
		}
	}
}
